import React from 'react';
import './HeroSection.css';

interface HeroSectionProps {
  backgroundClass: string;
  title: string;
  buttonText: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({ backgroundClass, title, buttonText }) => {
  return (
    <div className={`hero-section ${backgroundClass}`}>
      <div className="overlay"></div>
      <div className="hero-content">
        <h1>{title}</h1>
        <button>{buttonText}</button>
      </div>
    </div>
  );
};

export default HeroSection;
