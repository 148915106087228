import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import EventFeaturesSection from './components/EventFeaturesSection';
import EventSection from './components/EventSection';
import EventSection2 from './components/EventSection2';
import HostingEventSection from './components/HostingEventSection';
import Footer from './components/Footer';

const App: React.FC = () => {
  return (
    <div>
      <Header />
      <HeroSection 
        backgroundClass="image1"
        title="FIND THE BEST EVENT IN A SNAP" 
        buttonText="GET STARTED" 
      />
      <EventFeaturesSection />
      <EventSection/>
      <EventSection2/>
      <HostingEventSection/>
      <Footer />
    </div>
  );
};

export default App;
