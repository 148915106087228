import React from 'react';
import './EventFeaturesSection.css';

const EventFeaturesSection: React.FC = () => {
  return (
    <section className="event-features-section">
         <h2 className="heading">
         <span className="highlight">Bridging the Gap</span> <span className="normal-text">Between You and<br/> Your Crowd!</span>
      </h2>
      <div className="grid-container">
        <div className="grid-item item-1">
          <div className="overlay">
            <h3>Simple Event Page Creation</h3>
            <p>Easily create stunning event pages using our builder.</p>
          </div>
        </div>
        <div className="grid-item item-2">
          <div className="overlay">
            <h3>Seamless Ticketing Experience</h3>
            <p>Quick ticket purchases and secure payments for everyone.</p>
          </div>
        </div>
        <div className="grid-item item-3">
          <div className="overlay">
            <h3>Real-Time Audience Engagement</h3>
            <p>Stay connected with instant updates and notifications.</p>
          </div>
        </div>
        <div className="grid-item item-4">
          <div className="overlay">
            <h3>Comprehensive Analytics & Insights</h3>
            <p>Track ticket sales, behavior, and event performance.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventFeaturesSection;
