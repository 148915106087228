import React from 'react';
import './EventSection.css';

const EventSection: React.FC = () => {
  return (
    <div className="event-section">
      {/* First Section */}
      <div className="event-row event-row-left">
        <div className="overlay-content">
          <h2>Events of All Sizes, Simplified</h2>
          <p>
            We believe every event deserves the perfect ticketing solution, whether it’s a small community gathering or a major conference.
          </p>
          <button className="create-event-btn">Create an event</button>
        </div>
      </div>

      {/* Second Section */}
      <div className="event-row event-row-right">
        <div className="overlay-content">
          <h2>Tailored to your needs</h2>
          <p>
            Whether you're selling a few tickets or thousands, our platform handles it all with precision and speed.
          </p>
          <button className="bring-event-btn">Bring your event to life</button>
        </div>
      </div>
    </div>
  );
};

export default EventSection;
