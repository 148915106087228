import React from 'react';
import './EventSection2.css';

const EventSection2: React.FC = () => {
  return (
    <div className="event-section">
      {/* First Section */}
      <div className="event-row event-row-left">
        <div className="overlay-content">
          <h2>Multiple Event Types</h2>
          <p>
            From intimate workshops to large festivals, we offer customized ticketing options to suit your event's scale and audience.
          </p>
          <button className="explore-btn">Explore Options</button>
        </div>
      </div>

      {/* Second Section */}
      <div className="event-row event-row-right">
        <div className="overlay-content">
          <h2>Flexible Pricing Plans</h2>
          <p>
            Scale up or down based on your event needs, with flexible plans that grow with you
          </p>
          <button className="learn-btn">Learn more</button>
        </div>
      </div>
    </div>
  );
};

export default EventSection2;
