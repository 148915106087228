import React from 'react';
import './Footer.css';
import logo from '../assets/logo.png'; // Adjust the path as necessary

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <img src={logo} alt="Logo" className="footer-logo" />
      <p>&copy; 2024 COMING SOON</p>
    </footer>
  );
};

export default Footer;
