import React from 'react';
import './HostingEventSection.css';
import mobile1Img from '../assets/phone.png';


const HostingEventSection: React.FC = () => {
  return (
    <section className="hosting-event-section">
      <div className="left-content">
        <h1>Hosting an event?</h1>
        <p>
          Book a free call to learn how we can help you easily sell tickets in a snap.
        </p>
        <button className="contact-button">CONTACT US</button>
      </div>
      <div className="right-content">
      <img src={mobile1Img} alt="Mobile Screen 1" className="mobile-image mobile-image-1" />
      </div>
    </section>
  );
};

export default HostingEventSection;
