import React, { useEffect, useState } from 'react';
import './Header.css';
import logo1 from '../assets/logo.png'; // Adjust the path as necessary
import logo2 from '../assets/logo2.png'; // Adjust the path as necessary

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <img src={isScrolled ? logo2 : logo1} alt="Logo" className="logo"
        style={{ height: isScrolled ? '50px' : '40px' }} // Change the height for logo2
         />
      <nav>
        <ul className="nav-list">
          <li><a href="#how-it-works">How It Works</a></li>
          <li><a href="#collaborate">Collaborate</a></li>
          <li><a href="#contact-us">Contact Us</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
